import { Organization } from '@models/Organization';
import { roleComparator, RoleLevel } from '@models/Permission';
import { format } from 'date-fns';

export const SPONSOR_USER_GROUP = 3;

export interface User {
    first_name: string;
    last_name: string;
    email: string;
    uuid?: string | null;
    avatar_url: string | null;
    marketing_lab_name?: string | null;
    marketing_organization_name?: string | null;
    registration_completed?: boolean;
    role: string | null;
    organization?: Organization;
    organization_role?: RoleLevel;
    custom_plot_colors?: string[] | null;
    is_staff?: boolean;
    user_groups?: Group[];
}

export interface Group {
    id: number;
    name: string;
}

export type SimpleUser = Pick<User, 'first_name' | 'last_name' | 'avatar_url' | 'uuid'>;
export type TaggedUser = Pick<User, 'first_name' | 'last_name'>;

export type SharingMember = SimpleUser & {
    role_displayname: string;
    email?: string | null;
    uuid: string;
    role_shortname: RoleLevel;
    user_groups?: Group[];
};
export type SortableSharingMember = Pick<SharingMember, 'last_name' | 'role_shortname'>;
export type UserProfile = Pick<
    User,
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'avatar_url'
    | 'marketing_lab_name'
    | 'marketing_organization_name'
    | 'registration_completed'
    | 'role'
    | 'custom_plot_colors'
>;

export interface UserAuthentication {
    access_token?: string | null;
    refresh_token?: string | null;
}

/**
 * Returns firsts_name + last_name with a space between. Will return empty string if no user or fields are provided.
 * @param {Partial<SimpleUser>} user
 * @return {string}
 */
export const getFullName = (user?: Partial<SimpleUser> | null): string => {
    return `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim();
};

export const getCuteDate = (date: number) => {
    const dateDifference = Date.now() - date;
    // less than a minute
    if (dateDifference < 60_000) return 'Just now';
    // less than an hour
    if (dateDifference < 60 * 60_000) {
        const minuteDifference = Math.floor(dateDifference / 60_000);
        return `${minuteDifference} minute${minuteDifference > 1 ? 's' : ''} ago`;
    }
    // less than a day
    if (dateDifference < 24 * 60 * 60_000) {
        const hourDifference = Math.floor(dateDifference / (60 * 60_000));

        return `${hourDifference} hour${hourDifference > 1 ? 's' : ''} ago`;
    }
    // less than a week
    if (dateDifference < 7 * 24 * 60 * 60_000) {
        const dayDifference = Math.floor(dateDifference / (24 * 60 * 60_000));
        return `${dayDifference} day${dayDifference > 1 ? 's' : ''} ago`;
    }
    return format(date, 'MMM d, yyy');
};

/**
 * Given two members, return the comparison value. It will sort first by role level, with owner being first, then by last name
 * @param {SortableSharingMember} m1
 * @param {SortableSharingMember} m2
 * @return {any}
 */
export const sharingMembersComparator = (m1: SortableSharingMember, m2: SortableSharingMember) => {
    if (m1.role_shortname === m2.role_shortname) {
        return m1.last_name.localeCompare(m2.last_name);
    }
    return roleComparator(m1.role_shortname, m2.role_shortname);
};

/**
 * Returns true if the member is a sponsor
 * @param {User} member
 * @return {boolean}
 */
export const isSponsor = (user?: User | null): boolean => {
    return user?.user_groups?.some((g) => g.id === SPONSOR_USER_GROUP) ?? false;
};
