import { allEnumStringValues } from '@util/ObjectUtil';

export enum PermissionObjectName {
    experiment = 'experiment',
    project = 'project',
    team = 'team',
    organization = 'organization',
    biomarkerset = 'biomarkerset',
}

export enum RoleLevel {
    owner = 'owner', // Deprecated, use editor instead
    editor = 'editor',
    member = 'member',
    viewer = 'viewer',
}

export const allRoleLevels: RoleLevel[] = allEnumStringValues(RoleLevel);
export const defaultRoleLevels: RoleLevel[] = [RoleLevel.editor, RoleLevel.viewer];
export const orgRoleLevels: RoleLevel[] = [RoleLevel.editor, RoleLevel.viewer, RoleLevel.member];

export const roleComparator = (r1: RoleLevel, r2: RoleLevel) => {
    return allRoleLevels.indexOf(r1) - allRoleLevels.indexOf(r2);
};

export type CrudPermissions = {
    canEdit: boolean;
    canCreate: boolean;
    canArchive: boolean;
    canView: boolean;
    canDownload?: boolean;
};

export interface UserPermissionObject {
    user_permissions?: PermissionName[];
}

export enum PermissionName {
    /* Organization Permissions - e.g. the organization name, avatar, domain, members */
    own_organization = 'own_organization',
    edit_organization = 'edit_organization',
    view_organization = 'view_organization',

    // Organization users
    invite_organization_users = 'invite_organization_users',
    remove_organization_users = 'remove_organization_users',
    edit_organization_users = 'edit_organization_users',
    view_organization_analytics = 'view_organization_analytics',

    // Organization related entities (Funding_sources, Teams, Projects, Experiments)
    create_organization_funding_sources = 'create_organization_funding_sources',
    archive_organization_funding_sources = 'archive_organization_funding_sources',
    create_organization_teams = 'create_organization_teams',
    archive_organization_teams = 'archive_organization_teams',
    // create_organization_shareable_experiments = 'create_organization_shareable_experiments',

    /* Team Permissions - e.g. the team name, avatar, members */
    own_team = 'own_team',
    edit_team = 'edit_team',
    archive_team = 'archive_team',
    view_team = 'view_team',

    // Team users
    invite_team_users = 'invite_team_users',
    remove_team_users = 'remove_team_users',

    // Team related entities (Funding_sources, Projects, Experiments)
    create_team_projects = 'create_team_projects',
    archive_team_projects = 'archive_team_projects',
    archive_organization_public_projects = 'archive_organization_public_projects',
    create_organization_public_projects = 'create_organization_public_projects',
    create_organization_shareable_projects = 'create_organization_shareable_projects',
    archive_organization_shareable_projects = 'archive_organization_shareable_projects',

    /** Funding source has been renamed to Program, but the permission is still called funding source */
    assign_team_funding_sources = 'assign_team_funding_sources',

    /* Project Permissions - e.g. the project name, description, members */
    own_project = 'own_project',
    edit_project = 'edit_project',
    archive_project = 'archive_project',
    view_project = 'view_project',

    // Project users
    invite_project_users = 'invite_project_users',
    remove_project_users = 'remove_project_users',
    create_project_experiments = 'create_project_experiments',
    archive_project_experiments = 'archive_project_experiments',

    /* Experiment Permissions - e.g. the experiment name, description, members */
    own_experiment = 'own_experiment',
    edit_experiment = 'edit_experiment',
    archive_experiment = 'archive_experiment',
    view_experiment = 'view_experiment',
    archive_organization_shareable_experiments = 'archive_organization_shareable_experiments',
    create_organization_shareable_experiments = 'create_organization_shareable_experiments',
    view_experiment_report = 'view_experiment_report',
    view_experiment_history = 'view_experiment_history',

    //Biomarker Permissions
    edit_biomarkerset = 'edit_biomarkerset',
    view_biomarkerset = 'view_biomarkerset',

    // Experiment users
    invite_experiment_users = 'invite_experiment_users',
    remove_experiment_users = 'remove_experiment_users',

    // Attachments - e.g. the filename, description, size
    view_experiment_attachments = 'view_experiment_attachments',
    create_experiment_attachments = 'create_experiment_attachments',
    edit_experiment_attachments = 'edit_experiment_attachments',
    archive_experiment_attachments = 'archive_experiment_attachments',
    download_experiment_attachments = 'download_experiment_attachments',

    // Apps - Embedded apps
    view_organization_apps = 'view_organization_apps',
    create_organization_apps = 'create_organization_apps',
}

export type PermissionCheckOptions = {
    requires: PermissionName[] | PermissionName;
    any?: boolean;
    // object?: UserPermissionObject | null;
    additionalPermissions?: PermissionName[];
};

type ExpTypeLimit = {
    total_experiments: number;
    experiment_type: string;
};

export type FeatureResponse = {
    organization: {
        uuid: string;
        name: string;
        is_trial: boolean;
        trial_expires_at: string | null;
    };
    org_features: {
        domain_restriction: string;
        color_palettes: string[];
        subdomains: string[];
        sponsors_enabled: boolean;
    };
    team_features: {
        domain_restriction: string;
    };
    project_features: {
        domain_restriction: string;
        storyboards_enabled: boolean;
    };
    experiment_features: {
        ai_assistant_enabled: boolean;
        basespace_enabled: boolean;
        benchling_enabled: boolean;
        biomarkers_enabled: boolean;
        branding_enabled: boolean;
        canvas_enabled: boolean;
        comments_enabled: boolean;
        domain_restriction: string;
        embedded_apps_enabled: boolean;
        evidence_enabled: boolean;
        experiment_type_features: ExpTypeLimit[];
        literature_search_enabled: boolean;
        mentions_enabled: boolean;
        pipelines_over_limit: boolean;
        public_plots_enabled: boolean;
        targets_enabled: boolean;
        total_experiments: number;
        total_geo_requests: number;
        total_geo_submissions: number;
        total_pipeline_runs: number;
        workflows_enabled: boolean;
        knownlists_enabled: boolean;
    };
};
