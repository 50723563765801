export default {
    companyName: 'Pluto Bioinformatics',
    siteTitleLong: 'Pluto Bioinformatics - collaborative life sciences analysis & results management platform',
    siteDescription:
        'Make meaningful discoveries in a fraction of the time, keep them interactive and searchable forever. Run bioinformatics analyses and create visualizations in minutes, with no coding required.',
    helpUrl: 'https://help.pluto.bio',
    baseSpaceHelpUrl: 'https://help.pluto.bio/en/articles/6529553-basespace-pluto',
    siteMetaImageUrl: 'https://pluto.bio/og/pluto-bio.png',
    siteMetaVideoUrl: 'https://www.youtube.com/channel/UCDCnL58lbfFF8oA1YyXH28A',
    experimentMetaImageUrl: 'https://cdn.bfldr.com/2Q1IPX6I/at/3jqz2jm8nwjxwfjj57q69k/Public_experiment_metadata_image',
    projectMetaImageUrl: 'https://cdn.bfldr.com/2Q1IPX6I/at/nck3hpcs93632cfq6jfqtj99/Public_project_metadata_image',
    statisticsHelpUrl: 'https://help.pluto.bio/en/articles/5732581-statistical-tests-for-comparing-groups-of-samples',
    apiTokenHelpUrl: 'https://help.pluto.bio/en/articles/5739131-accessing-pluto-data-programmatically',
    analysisChangeLogUrl: 'https://help.pluto.bio/en/articles/6390578-change-log-analyses',
    managingColorsHelpUrl: 'https://help.pluto.bio/en/articles/6434735-managing-favorite-colors',
    survivalAnalysisHelpUrl: 'https://help.pluto.bio/en/articles/6200942-survival-analysis-in-pluto',
    embedPlotHelpUrl: 'https://help.pluto.bio/en/articles/6253449-embedding-pluto-plots',
    exportToBenchlingHelpUrl: 'https://help.pluto.bio/en/articles/6882230-benchling-pluto',
    searchPlotsHelpUrl: 'https://help.pluto.bio/en/articles/6268238-search-all-plots',
    editSampleDataHelpUrl: 'https://help.pluto.bio/en/articles/5181166-creating-and-editing-experiment-sample-data',
    editAssayDataHelpUrl: 'https://help.pluto.bio/en/articles/6562442-creating-and-editing-experiment-assay-data',
    requestExploreExperimentFormUrl:
        'https://docs.google.com/forms/d/e/1FAIpQLSdI_YMtGLSV1LPObdBsjBzRZtrTSpcHoNa71ViUq_cqcf2h6A/viewform',
    runAnalysisYoutubeVideoUrl: 'https://www.youtube.com/watch?v=vddgpwm95Ps&feature=youtu.be',
    cloneExperimentHelpUrl: 'https://help.pluto.bio/en/articles/6380506-clone-an-experiment',
    plotCitation: 'Plots created with Pluto (https://pluto.bio).',
    analysisCitation: 'Analysis performed using Pluto (https://pluto.bio).',
    preprocessCitation: 'Preprocess performed using Pluto (https://pluto.bio).',
    requestExploreExperimentFormUrlDynamic: ({ experimentId }: { experimentId?: string | null }) =>
        `https://share.hsforms.com/1-bHjIDkVTVm89bX4GZyf1Q5c2ld?pluto_experiment_id__plx__=${encodeURIComponent(
            experimentId ?? '',
        )}`,
    requestCloudUploadFormUrlDynamic: ({ experimentId }: { experimentId?: string | null }) =>
        `https://share.hsforms.com/1WkeKzHM4RtmD2bg9TtPlNQ5c2ld?pluto_experiment_id__plx__=${encodeURIComponent(
            experimentId ?? '',
        )}`,
    inviteLinkHelpUrl: '', // to be added
    commentsHelpUrl: 'https://help.pluto.bio/en/articles/comments-and-notes',
};
